import React, { useState } from 'react';
import { Modal, Typography, TextField, Button, styled, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 10,
    overflow: 'auto',
};

const DeleteModal = ({ row, closeDetails, isOpen,deleteRecord }) => {
    /* eslint-disable no-useless-concat */
    return (
        <Modal
            open={isOpen}
            onClose={closeDetails}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {/* <IconButton
                    onClick={closeDetails}
                    style={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton> */}
                <div style={{ display: 'flex', padding: "4%" }}>
                    Are you sure you want to delete this record?
                </div>
                <div style={{ display: 'flex', padding: "4%", justifyContent: "end" }}>
                    <Button variant="contained" style={{ backgroundColor: "black", marginLeft: "-14px",marginRight:"24px" }} onClick={()=>{deleteRecord(row._id)}}>
                        Yes
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: "white", marginLeft: "-14px",color:"black" }} onClick={closeDetails}>
                        No
                    </Button>
                </div>


            </Box>
        </Modal>

    );
};

export default DeleteModal;

