import React, { useState } from 'react';
import { Modal, Typography, TextField, Button, styled,IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  height: "60%",
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 10,
  overflow: 'auto',
};

const DetailsModal = ({ row, closeDetails, isOpen }) => {
  /* eslint-disable no-useless-concat */
  return (
    <Modal
      open={isOpen}
      onClose={()=>closeDetails(row._id)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <IconButton
          onClick={()=>closeDetails(row._id)}
          style={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ display: 'flex',padding:"4%" }}>
          <img src='/assets/images/avatars/avatar_13.jpg'
            width='80px'
            height='80px'
            alt='avatar'
            style={{
              borderRadius: '50%',
            }}
          />

          <div style={{ marginLeft: '10px' }}>
            <Typography variant='h5'>{row?.firstName} {row?.lastName}</Typography>
            <Typography variant='subtitle2'>{row?.email} </Typography>
            <Typography variant='subtitle2'>{row?.phone} </Typography>
            <Typography variant='subtitle2'>{new Date(row.createdDate).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })} </Typography>
          </div>
        </div>
        <Typography sx={{ mt: 2, px: 4,marginBottom:"20px" }}>
          <strong>Property Address: </strong> {row.propertyAddress}
          <br />
          <strong>Street No: </strong>{row.streetNumber}
          <br />
          <strong>Street Name: </strong>{row.streetName}
          <br />
          <strong>City/Suburb: </strong>{row.citySubrub}
          <br />
          <strong>State: </strong>{row.state}
          <br />
          <strong>Zip Code: </strong>{row.zipCode}
          <br />
          <strong>Highest Level of Education: </strong>{row.education}
          <br />
          <strong>Credit Score: </strong>{row.creditScore}
          <br />
          <strong>Bankruptcy: </strong>{row.bankruptcy}
          <br />
          <strong>Criminal Investigation: </strong>{row.criminalInvestigation}
          <br />
          <strong>Property Use: </strong>{row.propertyUse}
          <br />
          <strong>Property Type: </strong>{row.propertyType}
          <br />
          <strong>Purchase Price: $</strong>{new Intl.NumberFormat().format(row.purchasePrice)}
          <br />
          <strong>Rehab Budget: $</strong>{new Intl.NumberFormat().format(row.rehabBudget)}
          <br />
          <strong>Loan Amount Requested: $</strong>{new Intl.NumberFormat().format(row.loanAmountRequested)}
          <br />
          <strong>After Repair Value: $</strong>{new Intl.NumberFormat().format(row.afterRepairValue)}
          <br />
          <strong>Number of Units: </strong>{row.numberOfUnits}
        </Typography>
        
      </Box>
    </Modal>

  );
};

export default DetailsModal;

