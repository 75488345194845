import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Input,
  Box,
  TextField,
  CircularProgress,
} from '@mui/material';
import { green } from '@mui/material/colors';
import Iconify from '../components/iconify';

import DetailsModal from '../components/Modal/DetailsModal';
import DeleteModal from '../components/Modal/DeleteModal';

import Api from '../api/api';

export default function Record({ row, handleUpdate }) {
  const { email, firstName, phone, answer, _id, loanAmountRequested, citySubrub, createdDate, read } = row;
  const [showDetails, setShowDetails] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleDetails = () => {
    setShowDetails(true);
  };

  const closeDetails = (id) => {
    setShowDetails(false);
    // window.location.reload();
    handleRead(id);
  };

  const handleDelete = () => {
    setShowDelete(true);
  }
  const closeDelete = () => {
    setShowDelete(false);
  }

  const deleteRecord = (id) => {
    setShowDelete(false);
    Api.delete(`/api/record/${id}`)
      .then(res => {
        // window.location.reload();
        handleUpdate();
      })
  }
  const handleRead = (id) => {
    Api.put(`/api/record/${id}/mark`)
      .then(res => {
        handleUpdate();
      })
  }

  return (
    <TableRow hover key={_id} tabIndex={-1} role="checkbox" style={{fontWeight:"800"}}>

      <TableCell style={{ fontWeight: read ? "normal" : "bold" }}>
        {email}
      </TableCell>

      <TableCell align="left" style={{ fontWeight: read ? "normal" : "bold" }}>{firstName}</TableCell>

      <TableCell align="left" sx={{ width: '50px' }} style={{ fontWeight: read ? "normal" : "bold" }}>
        {phone}
      </TableCell>

      <TableCell align="left"  sx={{ overflow: 'hidden' }} style={{ fontWeight: read ? "normal" : "bold", maxWidth: "50px", }}>
        ${loanAmountRequested}
      </TableCell>


      <TableCell align="left" style={{ fontWeight: read ? "normal" : "bold" }}>
        {new Date(createdDate).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
      </TableCell>


      <TableCell align="left">
        <Button variant="contained" style={{ backgroundColor: "black", marginLeft: "-14px" }} onClick={handleDetails}>
          Details
        </Button>
      </TableCell>
      <TableCell align="left">
        <Button variant="contained" style={{ backgroundColor: "red", marginLeft: "-14px" }} onClick={handleDelete}>
          Delete
        </Button>
      </TableCell>

      {
        showDetails && (
          <DetailsModal row={row} closeDetails={closeDetails} isOpen={showDetails} />
        )
      }

      {
        showDelete && (
          <DeleteModal row={row} closeDetails={closeDelete} isOpen={showDelete} deleteRecord={deleteRecord} />
        )
      }


    </TableRow>
  );
}
