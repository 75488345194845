import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import { Container, Typography,Stack, TextField, InputAdornment, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../components/iconify';
import Api from '../api/api';

// ----------------------------------------------------------------------

export default function ProductsPage() {

  const navigate = useNavigate();
  const user = localStorage.getItem('user');
  const userId = localStorage.getItem('userId');
  const [showPassword, setShowPassword] = useState(false);
  const [email,setEmail] = useState(user);
  const [password,setPassword] = useState('');

  const handleClick = () => {
    console.log('clicked')
    const data = {
      email,
      password
    }
    Api.put(`/api/auth/user/${userId}`, data)
    .then(res => {
      if(res.status === 200){
        console.log("updated successfully");
        navigate('/')
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>Change Passowrd | CB Capital </title>
      </Helmet>

      <Container maxWidth="sm" align="left" sx={{marginLeft:'20px'}}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Update Login
        </Typography>

        <Stack spacing={3}>
        <TextField label="Email address" onChange={(e)=>{setEmail(e.target.value)}} autoComplete='off' value={email}/>

        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e)=>{setPassword(e.target.value)}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton size="large" type="submit" variant="contained" onClick={handleClick} sx={{ mt: 3, mb: 2 }}>
        Update
      </LoadingButton>




      </Container>
    </>
  );
}
