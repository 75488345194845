import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
// api
import Api from '../../../api/api';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
  },[]);

  const [showPassword, setShowPassword] = useState(false);
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');

  const handleClick = () => {
    const data = {
      email,
      password
    }
    Api.post('/api/auth/login',data)
    .then(res => {
      localStorage.setItem('userId',res.data.userId);
      localStorage.setItem('token',res.data.token);
      localStorage.setItem('user',res.data.email);
      navigate('/dashboard/user', { replace: true });
    })
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" onChange={(e)=>{setEmail(e.target.value)}} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e)=>{setPassword(e.target.value)}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} sx={{ mt: 3, mb: 2 }}>
        Login
      </LoadingButton>
    </>
  );
}
